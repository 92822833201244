// MAIN SECTION SUBMENU ITEMS PERMISSIONS ====>

export const CATALOG_MAIN_LIST_PERMISSION = {
  action: 'view',
  resource: 'Main List',
}
export const CATALOG_GENERAL_PERMISSION = {
  action: 'view',
  resource: 'General',
}
export const CATALOG_RATES_PERMISSION = {
  action: 'view',
  resource: 'Rates',
}
export const CATALOG_PHYSICAL_PROPERTIES_PERMISSION = {
  action: 'view',
  resource: 'Physical Properties',
}
export const CATALOG_COMPONENTS_PERMISSION = {
  action: 'view',
  resource: 'Components',
}
export const CATALOG_ATTACHMENTS_PERMISSION = {
  action: 'view',
  resource: 'Attachments',
}
export const CATALOG_PROCUREMENT_PERMISSION = {
  action: 'view',
  resource: 'Procurement',
}
export const CATALOG_SUBSTITUTES_PERMISSION = {
  action: 'view',
  resource: 'Substitutes',
}
export const CATALOG_ASSETS_PERMISSION22 = {
  action: 'view',
  resource: 'Assets',
}
export const CATALOG_AUDIT_PERMISSION = {
  action: 'view',
  resource: 'Audit',
}
export const CATALOG_REPORTS_PERMISSION = {
  action: 'view',
  resource: 'Reports',
}

// MAIN SECTION SUBMENU ITEMS PERMISSIONS =========>
// =============================================>
