export default {
  inventory: {},
  inventoryId: null,
  inventorySerializedAndNonSerializedVal: false,
  inventories: [],
  inventoryForm: {
    locations: [],
  },
  warehouses: {},
  product: {},
  stockProducts: [],
  isEditable: true,
  inventoryAudit: [],
  inventoriesList: [],
  inventoryItemsBarcode: null,
  inventoryItemsBarcodeList: [],
  orderPurchasesList: [],
  changeAssetToDamagedForm: {
    attachments: [],
  },
  exportPath: 'inventory-items/csv-export',
  importPath: 'inventory-items/csv-import',
  exportPathTemplate: 'inventory-items/csvTemplate',
  export: {},
  import: {},
  damagedInventoryItemID: null,
  inventoriesListRef: null,
  inventoriesLastOrder: null,
  exportImportButtonItems: [
    {
      btnTitle: 'Inventory information',
      actionType: 'Inventory',
    },
  ],
}
