<template>
  <sub-menu-component
    v-if="catalog.id"
    :menu-items="menuItems"
  >
    <portal
      v-if="catalog.id"
      to="body-top"
    >
      <p class="text-center font-weight-bolder font-medium-5 mb-1">
        {{ portalTopTitle }}
      </p>
    </portal>
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
  <div v-else>
    <div v-if="!error">
      <b-core-skeleton />
    </div>
    <div v-else>
      <component-not-found
        title="Catalog List"
        :button-link="{name: 'home-catalog'}"
      />
    </div>
  </div>
</template>

<script>
import BaseCatalogService from '@/views/main/catalog/service/BaseCatalogService'
import store from '@/store'
import inventoryModule from '@/store/main/inventory'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Catalog',
  inject: ['catalogModuleName'],

  data() {
    return {
      error: false,
      menuItems: [],
    }
  },
  computed: {
    catalog() {
      return this.$store.state[this.catalogModuleName].catalog
    },
    getCatalog() {
      return this.$store.state[this.catalogModuleName].catalog
    },
    portalTopTitle() {
      const { sku, name } = this.getCatalog
      return `${sku} - ${name}`
    },
  },
  created() {
    this.$store.commit(`${this.catalogModuleName}/GET`, {})
    this.$store.commit(`${this.catalogModuleName}/LIST`, {})
  },
  mounted() {
    this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/SET_INVENTORY_ID`, this.$route.params?.id)

    this.$store.dispatch(`${this.catalogModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/GET_PRODUCT`, data)
      this.$store.commit(`${this.INVENTORIES_MODULE_NAME}/SET_INVENTORY_SERIALIZED_AND_NON_SERIALIZED_VAL`, data.detail.needs_individual_asset_id)
    })
      .catch(err => {
        this.error = true
        this.errorNotification(this, err)
      })
  },
  methods: {
    updateMenu(active) {
      this.menuItems = new BaseCatalogService().updateMenu(active, this.catalog.type.id)
    },
  },
  setup() {
    const INVENTORIES_MODULE_NAME = 'inventories'
    if (!store.hasModule(INVENTORIES_MODULE_NAME)) store.registerModule(INVENTORIES_MODULE_NAME, inventoryModule)

    onUnmounted(() => {
      if (store.hasModule(INVENTORIES_MODULE_NAME)) store.unregisterModule(INVENTORIES_MODULE_NAME)
    })
    return {
      INVENTORIES_MODULE_NAME,
    }
  },

}
</script>
