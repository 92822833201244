export default {
  GET(state, data) {
    state.inventory = { ...data }
  },
  LIST(state, data) {
    state.inventories = data
  },
  STOCK_PRODUCTS_LIST(state, data) {
    state.stockProducts = data
  },
  SET_INVENTORY_FORM(state, data) {
    state.inventoryForm = { ...data }
  },
  SET_CHANGE_ASSET_TO_DAMAGED_FORM(state, data) {
    state.changeAssetToDamagedForm = { ...data }
  },
  SET_INVENTORY_ID(state, data) {
    state.inventoryId = data
  },
  SET_LAST_ORDER_ID(state, data) {
    state.lastOrderId = data
  },
  SET_INVENTORY_SERIALIZED_AND_NON_SERIALIZED_VAL(state, data) {
    state.inventorySerializedAndNonSerializedVal = data
  },
  SET_FOR_INVENTORY_LOCATION_WAREHOUSES(state, data) {
    state.warehouses = { ...data }
  },
  GET_PRODUCT(state, data) {
    state.product = { ...data }
  },
  SET_IS_EDITABLE(state, bool) {
    state.isEditable = bool
  },
  SET_INVENTORY_AUDIT(state, data) {
    state.inventoryAudit = data
  },
  SET_INVENTORIES_LIST(state, data) {
    state.inventoriesList = data
  },
  SET_INVENTORIES_LIST_REF(state, data) {
    state.inventoriesListRef = data
  },
  SET_INVENTORY_GENERATED_BARCODE(state, data) {
    state.inventoryItemsBarcode = data
  },
  SET_INVENTORY_GENERATED_BARCODE_LIST(state, data) {
    state.inventoryItemsBarcodeList = data
  },
  SET_DAMAGED_INVENTORY_ITEM_ID(state, id) {
    state.damagedInventoryItemID = id
  },
  SET_INVENTORY_LAST_ORDER(state, id) {
    state.inventoriesLastOrder = id
  },
  SET_ORDER_PURCHASES(state, list) {
    state.orderPurchasesList = list
  },
  SET_EXPORT(state, data) {
    state.export = { ...data }
  },
  SET_IMPORT(state, data) {
    state.import = { ...data }
  },
}
