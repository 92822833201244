import {
  CATALOG_GENERAL_PERMISSION,
  CATALOG_RATES_PERMISSION,
  CATALOG_PROCUREMENT_PERMISSION,
  CATALOG_PHYSICAL_PROPERTIES_PERMISSION,
  CATALOG_COMPONENTS_PERMISSION,
  CATALOG_ATTACHMENTS_PERMISSION,
  CATALOG_SUBSTITUTES_PERMISSION,
  CATALOG_AUDIT_PERMISSION,
  CATALOG_REPORTS_PERMISSION, CATALOG_ASSETS_PERMISSION, CATALOG_ASSETS_PERMISSION22,
} from '@/views/components/sub-menu/meta/main-sub-menu/home-catalog'
import {
  L_ACTIVE_CHECK_LF_BOX,
  L_CURRENCY_MASK_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  WYSIWYG_EDITOR,
  L_TAGS_INPUT, TEXTAREA_INPUT, CHECKBOX_LEF,
} from '@/views/components/DynamicForm/constants'
import mappingFields from '@/libs/mappingFields'
import {
  CATALOG_TYPE_COMPONENT,
  CATALOG_TYPE_CONTAINER,
  CATALOG_TYPE_NON_STOCK,
  CATALOG_TYPE_PRODUCT,
} from '@/views/main/catalog/catalogType'
import { isUndefined } from '@core/components/b-table-responsive/table-responsive/table/utils/inspect'

export default class BaseCatalogService {
    ACCESS_ABILITY_CATALOG = { action: 'change', subject: 'General' }

    catalogFormFields = {
      sku: {
        type: TEXT_INPUT,
        label: 'SKU',
        placeholder: 'Type or leave blank for autogenerate...',
      },
      name: {
        type: TEXT_INPUT,
        label: 'Name',
        rules: 'required',
        placeholder: 'Type...',
      },
      turnaround_time: {
        type: TEXT_INPUT,
        label: 'Turnaround time (in minutes)',
        rules: 'required|numeric',
        placeholder: 'Type...',
      },
      brand_id: {
        type: SELECT_INPUT,
        label: 'Brand',
        store: 'brandsList',
        rules: 'required',
        filterBy: 'name',
        options: {
          label: 'name',
        },
      },
      brand_name: {
        type: TEXT_INPUT,
        label: 'Name',
        rules: 'required',
        placeholder: 'Type...',
      },
      brand_is_active: {
        type: CHECKBOX_LEF,
        label: 'Active',
      },
      type_id: {
        type: SELECT_INPUT,
        label: 'Type',
        store: 'productTypesList',
        rules: 'required',
        getList: res => res.data.data.data.filter(item => item.id !== CATALOG_TYPE_NON_STOCK),
        options: {
          label: 'name',
          excludeOptions: options => options.filter(option => option.id !== 4),
        },
      },
      categories: {
        type: L_TAGS_INPUT,
        label: 'Category',
        rules: 'required',
        store: 'categoriesList',
      },
      tags: {
        type: L_TAGS_INPUT,
        label: 'Tags',
        store: 'tagsList',
      },
      is_active: {
        type: CHECKBOX_LEF,
        label: 'Active',
        rules: 'required',
      },
      non_searchable: {
        type: CHECKBOX_LEF,
        label: 'Non-searchable',
        rules: 'required',
      },
      pick_time_per_item: {
        type: TEXT_INPUT,
        label: 'Pick time per item (in minutes)',
        placeholder: 'Type...',
      },
      receive_time_per_item: {
        type: TEXT_INPUT,
        label: 'Receive time per item (in minutes)',
        placeholder: 'Type...',
      },
      pack_time_per_item: {
        type: TEXT_INPUT,
        label: 'Pack time per item (in minutes)',
        placeholder: 'Type...',
      },
      put_back_time_per_item: {
        type: TEXT_INPUT,
        label: 'Put back time per item (in minutes)',
        placeholder: 'Type...',
      },
      allow_discount: {
        type: TEXT_INPUT,
        label: 'Allow discount (as percentage) up to',
        placeholder: 'Type...',
      },
    }

    catalogDetailsFormFields = {
      mpn: {
        type: TEXT_INPUT,
        label: 'Manufacturer Part Number',
        placeholder: 'Type...',
      },
      msrp: {
        type: L_CURRENCY_MASK_INPUT,
        label: 'Manufacturer Suggested Retail Price (MSRP)',
        placeholder: 'Type...',
      },
      comments: {
        type: TEXTAREA_INPUT,
        label: 'Comments',
        options: {
          placeholder: 'Write notes here...',
        },
      },
      model: {
        type: TEXT_INPUT,
        label: 'Model',
        placeholder: 'Type...',
      },
      // supplier_sku: {
      //   type: TEXT_INPUT,
      //   label: 'Supplier SKU',
      //   placeholder: 'Type...',
      //   rules: 'required',
      // },
      replacement_cost: {
        type: L_CURRENCY_MASK_INPUT,
        label: 'Replacement Cost',
        placeholder: 'Type...',
        rules: 'required',
      },
      notes: {
        type: WYSIWYG_EDITOR,
        label: 'Description',
      },
      can_be_procured: {
        type: CHECKBOX_LEF,
        label: 'Can be procured',
      },
      can_be_rented_individually: {
        type: CHECKBOX_LEF,
        label: 'Can be rented',
      },
      can_be_sub_rented: {
        type: CHECKBOX_LEF,
        label: 'Can be sub-rented',
      },
      can_be_sold: {
        type: CHECKBOX_LEF,
        label: 'Can be sold',
      },
      can_be_transferred: {
        type: CHECKBOX_LEF,
        label: 'Can be transferred',
      },
      requires_container: {
        type: CHECKBOX_LEF,
        label: 'Requires a container',
      },
      serialized: {
        type: CHECKBOX_LEF,
        label: 'Is serialized',
      },
      needs_individual_asset_id: {
        type: CHECKBOX_LEF,
        label: 'Needs Individual asset ID',
      },
      requires_individual_asset_price: {
        type: CHECKBOX_LEF,
        label: 'Requires Individual asset price',
      },
    }

    disabledFields = {
      fields: ['sku', 'name', 'model', 'tags', 'notes'],
    }

    getDisabledFieldsOnUpdate() {
      return this.disabledFields.fields
    }

    getAllFormFields() {
      return {
        ...this.catalogFormFields,
        ...this.catalogDetailsFormFields,
      }
    }

    beforeMappingFields(data) {
      return mappingFields(Object.keys(this.catalogFormFields), data, {
        id: data.id,
        // type_id: data.type?.id,
        type_id: data.type,
        brand_id: data.brand,
        tags: data.tags ? data.tags.map(tag => tag.label) : [],
        categories: data.categories ? data.categories.map(category => category.label) : [],
      })
    }

    beforeMappingDetailFields(data) {
      return mappingFields(Object.keys(this.catalogDetailsFormFields), data, {
        msrp: Number(data.msrp),
      })
    }

    afterMappingFields(data) {
      return mappingFields(Object.keys(this.catalogFormFields), data, {
        id: data.id,
        brand_id: data.brand_id?.id,
        type_id: isUndefined(data.id) ? data.type_id : data.type_id.id,
      })
    }

    afterMappingDetailFields(data) {
      return mappingFields(Object.keys(this.catalogDetailsFormFields), data)
    }

    // eslint-disable-next-line class-methods-use-this
    menuItems(create) {
      return [
        {
          name: create ? 'home-catalog-create' : 'home-catalog-general-info',
          layout: 'General',
          ...CATALOG_GENERAL_PERMISSION,
        },
        {
          name: 'home-catalog-rates',
          layout: 'Rates',
          ...CATALOG_RATES_PERMISSION,
        },
        {
          name: 'home-catalog-phy-properties',
          layout: 'Physical Properties',
          ...CATALOG_PHYSICAL_PROPERTIES_PERMISSION,
        },
        {
          name: 'home-catalog-components',
          layout: 'Components',
          ...CATALOG_COMPONENTS_PERMISSION,
        },
        {
          name: 'home-catalog-attachments',
          layout: 'Attachments',
          ...CATALOG_ATTACHMENTS_PERMISSION,
        },
        {
          name: 'home-catalog-procurement',
          layout: 'Procurement',
          ...CATALOG_PROCUREMENT_PERMISSION,
        },
        {
          name: 'home-catalog-substitutes',
          layout: 'Substitutes',
          ...CATALOG_SUBSTITUTES_PERMISSION,
        },
        {
          name: 'home-catalog-assets',
          layout: 'Assets',
        },
        {
          name: 'home-catalog-audit',
          layout: 'Audit',
          ...CATALOG_AUDIT_PERMISSION,
        },
        {
          name: 'home-catalog-reports',
          layout: 'Reports',
          ...CATALOG_REPORTS_PERMISSION,
        },
      ]
    }

    // eslint-disable-next-line class-methods-use-this
    menuItemsCom(create) {
      return [
        {
          name: create ? 'home-catalog-create' : 'home-catalog-general-info',
          layout: 'General',
        },
        {
          name: 'home-catalog-rates',
          layout: 'Rates',
        },
        {
          name: 'home-catalog-phy-properties',
          layout: 'Physical Properties',
        },
        {
          name: 'home-catalog-attachments',
          layout: 'Attachments',
        },
        {
          name: 'home-catalog-procurement',
          layout: 'Procurement',
        },
        {
          name: 'home-catalog-substitutes',
          layout: 'Substitutes',
        },
        {
          name: 'home-catalog-assets',
          layout: 'Assets',
        },
        {
          name: 'home-catalog-audit',
          layout: 'Audit',
        },
        {
          name: 'home-catalog-reports',
          layout: 'Reports',
        },
      ]
    }

    // eslint-disable-next-line class-methods-use-this
    menuItemsService(create) {
      return [
        {
          name: create ? 'home-catalog-create' : 'home-catalog-general-info',
          layout: 'General',
        },
        {
          name: 'home-catalog-rates',
          layout: 'Rates',
        },
        {
          name: 'home-catalog-attachments',
          layout: 'Attachments',
        },
        {
          name: 'home-catalog-procurement',
          layout: 'Procurement',
        },
        {
          name: 'home-catalog-substitutes',
          layout: 'Substitutes',
        },
        {
          name: 'home-catalog-assets',
          layout: 'Assets',
        },
        {
          name: 'home-catalog-audit',
          layout: 'Audit',
        },
        {
          name: 'home-catalog-reports',
          layout: 'Reports',
        },
      ]
    }

    // eslint-disable-next-line class-methods-use-this
    getMainCatalogMenuItems(active, menuName, params = {}, create = false) {
      const menus = this[menuName](create)
      // eslint-disable-next-line no-unexpected-multiline
      menus.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = item.name === active
        // eslint-disable-next-line no-param-reassign
        item.route = { name: item.name, params }
        // eslint-disable-next-line no-param-reassign
        item.disabled = (create && item.name !== active)
      })
      return menus
    }

    updateMenu(active, typeID, params, create) {
      switch (typeID) {
        case CATALOG_TYPE_PRODUCT:
          return this.getMainCatalogMenuItems(active, 'menuItems', params, create)
        case CATALOG_TYPE_CONTAINER:
          return this.getMainCatalogMenuItems(active, 'menuItems', params, create)
        case CATALOG_TYPE_COMPONENT:
          return this.getMainCatalogMenuItems(active, 'menuItemsCom', params, create)
        default:
          return this.getMainCatalogMenuItems(active, 'menuItemsService', params, create)
      }
    }
}
